import { useEffect } from "react";

export default function Privacy() {
  useEffect(() => {
    // Scroll to the top of the page when the component mounts
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className=" text-gray-900 md:pt-8 lg:pt-20">
      <div className="max-w-3xl mx-auto bg-white p-6 ">
        <h1 className="text-3xl font-bold mb-6">Privacy Policy for GameTask</h1>
        <p className="text-sm text-gray-600 mb-4">
          Last Updated: October 16th, 2024
        </p>

        <p className="mb-4">
          At GameTask, we are committed to protecting your privacy. This Privacy
          Policy outlines how we collect, use, and safeguard your personal
          information when you use our services. By accessing or using GameTask,
          you agree to the terms outlined in this policy.
        </p>

        <h2 className="text-2xl font-semibold mt-8 mb-4">
          1. Information We Collect
        </h2>

        <h3 className="text-xl font-semibold mt-6 mb-3">
          a. Personal Information
        </h3>
        <ul className="list-disc list-inside mb-4">
          <li>
            <strong>Contact Information:</strong> Name, email address, and phone
            number.
          </li>
          <li>
            <strong>Account Credentials:</strong> Username and password.
          </li>
          <li>
            <strong>Profile Information:</strong> Avatar, account preferences,
            in-platform statistics, responses to forms, and other details you or
            your employer choose to provide.
          </li>
          <li>
            <strong>Payment Information:</strong> If you make purchases through
            GameTask, we collect billing address and payment method details
            (processed securely through third-party payment processors).
          </li>
        </ul>

        <h3 className="text-xl font-semibold mt-6 mb-3">
          b. Non-Personal Information
        </h3>
        <ul className="list-disc list-inside mb-4">
          <li>
            <strong>Usage Data:</strong> Usage activity, platform sessions
            logged, achievement progress, pages visited, features used, and time
            spent on our platform.
          </li>
          <li>
            <strong>Device Information:</strong> Device type, operating system,
            browser type, IP address, and unique device identifiers.
          </li>
          <li>
            <strong>Location Data:</strong> General geographic location based on
            IP address or GPS (with your consent).
          </li>
        </ul>
        <h2 className="text-2xl font-semibold mt-8 mb-4">
          2. How We Use Your Information
        </h2>
        <ul className="list-disc list-inside mb-4">
          <li>
            <strong>To Provide and Maintain Services:</strong> Facilitate and
            enhance your experience using GameTask.
          </li>
          <li>
            <strong>To Enhance User Experience:</strong> Personalize content,
            provide tailored recommendations, and improve experiences based on
            your preferences and activity.
          </li>
          <li>
            <strong>To Process Transactions:</strong> Manage subscriptions,
            in-app purchases, and provide customer support related to billing.
          </li>
          <li>
            <strong>To Communicate:</strong> Send important updates,
            notifications about deadlines, promotional offers, and respond to
            your inquiries.
          </li>
          <li>
            <strong>To Ensure Security:</strong> Detect and prevent fraud,
            unauthorized activities, and enforce our terms and policies.
          </li>
          <li>
            <strong>To Conduct Research and Analytics:</strong> Analyze usage
            patterns to improve app functionality, develop new features, and
            conduct user satisfaction surveys.
          </li>
        </ul>

        <h2 className="text-2xl font-semibold mt-8 mb-4">
          3. Sharing of Information
        </h2>

        <ul className="list-disc list-inside mb-4">
          <li>
            <strong>Service Providers:</strong> We may share information with
            third-party vendors who assist in operating our services (e.g.,
            cloud hosting, payment processing, analytics, customer support).
            These parties are obligated to keep your information confidential
            and use it only for the purposes of providing services to us.
          </li>
          <li>
            <strong>Social Features:</strong> If you use social features of
            GameTask, your username and profile information may be visible to
            other users.
          </li>
          <li>
            <strong>Third-Party Integrations:</strong> With your consent, we may
            share data with third-party platforms to enhance your GameTask
            experience.
          </li>
          <li>
            <strong>Legal Compliance:</strong> We may disclose information if
            required by law, regulation, legal process, or governmental request.
          </li>
          <li>
            <strong>Business Transfers:</strong> In the event of a merger,
            acquisition, reorganization, or asset sale, your information may be
            transferred as part of that transaction.
          </li>
        </ul>

        <p className="mb-4">
          <strong>
            No mobile information will be shared with third parties/affiliates
            for marketing/promotional purposes. All other categories exclude
            text messaging originator opt-in data and consent; this information
            will not be shared with any third parties.
          </strong>
        </p>
        <h2 className="text-2xl font-semibold mt-8 mb-4">
          4. Security Measures
        </h2>
        <p className="mb-4">
          We implement industry-standard security measures to protect your
          personal information, including:
        </p>

        <ul className="list-disc list-inside mb-4">
          <li>
            <strong>Encryption:</strong> Protecting data transmission using
            SSL/TLS protocols.
          </li>
          <li>
            <strong>Access Controls:</strong> Restricting access to personal
            information to authorized personnel only.
          </li>
          <li>
            <strong>Regular Audits:</strong> Conducting security assessments and
            penetration testing.
          </li>
          <li>
            <strong>Data Minimization:</strong> Storing only necessary personal
            data and pseudonymizing where possible.
          </li>
        </ul>

        <p className="mb-4">
          However, please note that no method of transmission over the internet
          or electronic storage is 100% secure.
        </p>

        <h2 className="text-2xl font-semibold mt-8 mb-4">
          5. Your Rights and Choices
        </h2>
        <ul className="list-disc list-inside mb-4">
          <li>
            <strong>Access and Correction:</strong> You can access, update, or
            correct your personal information at any time through your account
            settings.
          </li>
          <li>
            <strong>Data Portability:</strong> Request a copy of your personal
            data in a commonly used electronic format.
          </li>
          <li>
            <strong>Data Deletion:</strong> Request the deletion of your
            personal data by contacting us at{" "}
            <a href="mailto:contact@gametask.com" className="text-blue-600">
              contact@gametask.com
            </a>
            .
          </li>
          <li>
            <strong>Opt-Out of Communications:</strong> Unsubscribe from
            promotional emails by following the instructions in the email or
            adjusting your account settings.
          </li>
          <li>
            <strong>Cookie Preferences:</strong> Manage or disable cookies
            through your browser settings or our cookie consent tool.
          </li>
          <li>
            <strong>Do Not Track Signals:</strong> Our website currently does
            not respond to "Do Not Track" signals.
          </li>
        </ul>

        <h2 className="text-2xl font-semibold mt-8 mb-4">
          6. Children's Privacy
        </h2>
        <p className="mb-4">
          GameTask is not intended for individuals under the age of 13. We do
          not knowingly collect personal information from children under 13. If
          we become aware of such data, we will delete it promptly. Parents or
          guardians who believe their child has provided us with personal
          information can contact us at{" "}
          <a href="mailto:contact@gametask.com" className="text-blue-600">
            contact@gametask.com
          </a>
          .
        </p>

        <h2 className="text-2xl font-semibold mt-8 mb-4">
          7. International Data Transfers
        </h2>
        <p className="mb-4">
          Your information may be transferred to and processed in countries
          other than your own, including countries outside the European Economic
          Area (EEA) that may have different data protection laws. We ensure
          appropriate safeguards are in place to protect your data, such as
          standard contractual clauses approved by relevant authorities
        </p>

        <h2 className="text-2xl font-semibold mt-8 mb-4">
          8. Cookies and Similar Technologies
        </h2>
        <p className="mb-4">
          We use cookies, web beacons, and other tracking technologies to:
        </p>

        <ul className="list-disc list-inside mb-4">
          <li>
            <strong>Remember Preferences:</strong> Save your settings and
            preferences for future visits.
          </li>
          <li>
            <strong>Authentication:</strong> Keep you logged into your account.
          </li>
          <li>
            <strong>Analytics:</strong> Understand how you use our services to
            improve performance and develop new features.
          </li>
          <li>
            <strong>Advertising:</strong> Deliver personalized advertisements
            based on your interests (with your consent where required).
          </li>
        </ul>

        <p className="mb-4">
          You can manage your cookie preferences through your browser settings
          or our website's cookie management tool.
        </p>

        <h2 className="text-2xl font-semibold mt-8 mb-4">
          9. Third-Party Services and Links
        </h2>
        <p className="mb-4">
          Our services may contain links to third-party websites not operated by
          GameTask. We are not responsible for their privacy practices. Review
          their policies before providing personal information.
        </p>

        <h2 className="text-2xl font-semibold mt-8 mb-4">
          10. User-Generated Content
        </h2>
        <p className="mb-4">
          Any content you post publicly on our platform (e.g., comments,
          reviews, forum posts) may be viewed by other users. Please exercise
          caution when sharing personal information in public areas
        </p>

        <h2 className="text-2xl font-semibold mt-8 mb-4">11. Data Retention</h2>
        <p className="mb-4">
          We retain your personal information only as long as necessary to
          fulfill the purposes outlined in this Privacy Policy, comply with
          legal obligations, resolve disputes, and enforce our agreements
        </p>

        <h2 className="text-2xl font-semibold mt-8 mb-4">
          12. Changes to This Privacy Policy
        </h2>
        <p className="mb-4">
          We may update this Privacy Policy from time to time to reflect changes
          in our practices or for legal reasons. We will notify you of any
          significant changes by posting a notice on our website or by other
          appropriate means. Your continued use of GameTask after such updates
          constitutes your acceptance of the revised policy.
        </p>

        <h2 className="text-2xl font-semibold mt-8 mb-4">13. Contact Us</h2>
        <p className="mb-4">
          If you have any questions, concerns, or requests regarding this
          Privacy Policy or our data practices, please contact us at:
        </p>
        <ul className="list-disc list-inside mb-4">
          <li>
            Email:{" "}
            <a href="mailto:contact@gametask.com" className="text-blue-600">
              contact@gametask.com
            </a>
          </li>
        </ul>
      </div>
    </div>
  );
}
