import { t } from "i18next";
import React from "react";
import Button from "./Button";

const DemoBanner: React.FC = () => {
  const handleBook = () => {
    window.open("https://calendly.com/gametask/demo", "_blank");
  };

  return (
    <div className="relative flex flex-col items-center justify-center h-screen bg-white overflow-hidden">
      {/* Background Circles */}
      {/* Left Side Circles */}
      <img
        src="/img/red-circle.png"
        alt="Red Circle"
        className="absolute top-[100px] left-[-150px] w-[300px] md:w-[400px] opacity-70 "
      />
      <img
        src="/img/blue-circle.png"
        alt="Blue Circle"
        className="absolute top-[300px] left-[-200px] w-[500px] opacity-50 "
      />

      {/* Top Side Circles */}
      <img
        src="/img/red-circle.png"
        alt="Red Circle"
        className="absolute top-[-150px] left-[calc(50%-200px)] md:left-[calc(50%-350px)] w-[250px] md:w-[500px] opacity-90 "
      />
      <img
        src="/img/blue-circle.png"
        alt="Blue Circle"
        className="absolute top-[-200px] left-[calc(50%-100px)] w-[600px] opacity-50 "
      />

      {/* Right-Bottom Side Circles */}
      <img
        src="/img/red-circle.png"
        alt="Red Circle"
        className="absolute bottom-[-25px] -right-[100px] w-[300px] opacity-65 "
      />
      <img
        src="/img/blue-circle.png"
        alt="Blue Circle"
        className="absolute bottom-[125px] md:bottom-[-50px] right-[-200px] w-[600px] opacity-90"
      />

      {/* Foreground Content */}
      <div className="relative text-center p-4">
        <h1
          className="text-[2.5rem] md:text-[3.5rem] font-bold leading-tight"
          dangerouslySetInnerHTML={{ __html: t("hero.title") }}
        ></h1>

        <p
          className="text-[1.125rem] md:text-2xl text-gray-700 mt-4 "
          dangerouslySetInnerHTML={{ __html: t("hero.description") }}
        ></p>

        <Button
          title="bookDemo"
          onClick={handleBook}
          className="mt-12"
        ></Button>
      </div>
    </div>
  );
};

export default DemoBanner;
