import { useTranslation } from "react-i18next";
import { TranslationKeys } from "../i18n";

interface Props extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  title: TranslationKeys;
  size?: "md" | "lg";
}
const Button = ({ title, size = "md", className, ...rest }: Props) => {
  const { t } = useTranslation();

  let classNames = className + " text-white bg-primary rounded-xl";

  if (size === "md") {
    classNames += " px-6 py-2 ";
  } else if (size === "lg") {
    classNames += " px-8 py-3 text-lg font-medium";
  }

  return (
    <button className={classNames} {...rest}>
      {t(title)}
    </button>
  );
};

export default Button;
