import { useTranslation } from "react-i18next";
import Benefits from "./Benefits";
import Engagement from "./Engagement";
import Hero from "./Hero";
import Tabs from "./Tabs";

export default function Home() {
  const { t } = useTranslation();

  return (
    <div className="">
      <Hero />
      <section id="howWorks" className="pt-4 pb-4 lg:pt-12">
        <span
          className="text-2xl font-bold  lg:text-4xl text-center block"
          dangerouslySetInnerHTML={{ __html: t("features.title") }}
        ></span>
      </section>

      <Tabs></Tabs>
      <Benefits></Benefits>
      <Engagement></Engagement>
    </div>
  );
}
