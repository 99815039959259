import { useTranslation } from "react-i18next";

export default function Engagement() {
  const { t } = useTranslation();
  return (
    <div className="relative mt-8 flex justify-center lg:mt-16 lg:pt-12 mb-12">
      <div
        aria-hidden="true"
        className="absolute  right-0 left-0 top-10  opacity-40 mt-12 z-10 lg:flex lg:justify-between  "
      >
        <div className="blur-[80px] h-[150px] lg:h-[275px]  w-[275px]  relative bg-primary "></div>
        <div className="blur-[80px]  h-[150px]  lg:h-[310px]  w-[310px]  lg:-bottom-24 relative bg-secondary"></div>
      </div>
      <div
        className="bg-opacity-40 flex items-center flex-col lg:gap-5 lg:flex-row m-auto justify-center w-full max-w-[1000px] 
      relative z-20 px-8 bg-white  p-8 rounded-2xl mx-8"
      >
        <div className="lg:w-[45%]">
          <img
            src={"/img/puzzle-computer.png"}
            width="616"
            height="617"
            className={"object-cover mt-4"}
            alt="Hero Illustration"
            loading="eager"
          />
        </div>

        <div className="lg:flex-1">
          <p className="text-2xl font-semibold my-2 pt-4 lg:pb-4">
            {t("engagement.title")}
          </p>
          <p className="lg:mb-5">{t("engagement.description")}</p>

          <a
            href="#howWorks"
            className="p-3 text-lg font-medium text-center 
            text-secondary rounded-full flex flex-row mt-8  items-center "
          >
            {t("engagement.learnHow")}
            <img
              src={"/img/icon-caret.png"}
              alt="next"
              className="ml-2 w-4 mt-1 h-fit"
            ></img>
          </a>
        </div>
      </div>
    </div>
  );
}
