import React, { useState } from "react";
import { useTranslation } from "react-i18next";

const LanguageSelector: React.FC = () => {
  const { i18n } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);
  const [flag, setFlag] = useState(
    "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQGVKBxTKYb7_f74m0U7_TsZFtPTLnNsmck5w&s"
  );

  const languages = [
    {
      code: "en",
      name: "English",
      flag: "https://cdn.britannica.com/76/4476-050-E643DD49/Betsy-Ross-legend-flag-united-states.jpg",
    },
    {
      code: "es",
      name: "Español",
      flag: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQGVKBxTKYb7_f74m0U7_TsZFtPTLnNsmck5w&s",
    },
  ];

  const changeLanguage = (lang: string) => {
    i18n.changeLanguage(lang);
    setIsOpen(false); // Close dropdown after selecting a language
    setFlag(languages.find((l) => l.code === lang)?.flag ?? "");
  };

  return (
    <div className="relative inline-block text-left">
      <button
        onClick={() => setIsOpen(!isOpen)}
        className="flex items-center px-4 py-2 bg-gray-200 rounded-xl hover:bg-gray-300 focus:outline-none"
      >
        <img width="30px" className="mr-2" src={flag} alt={"Flag"}></img>
        <span className="mr-2"></span>
        <span>{i18n.language === "en" ? "English" : "Español"}</span>
      </button>

      {isOpen && (
        <div className="absolute right-0 mt-2 w-32 bg-white border border-gray-200 rounded shadow-lg z-10">
          {languages.map(({ code, name, flag }) => (
            <button
              key={code}
              onClick={() => changeLanguage(code)}
              className="flex items-center w-full px-4 py-2 text-left text-gray-700 hover:bg-gray-100"
            >
              <img width="30px" className="mr-2" src={flag} alt={name}></img>
              <span>{name}</span>
            </button>
          ))}
        </div>
      )}
    </div>
  );
};

export default LanguageSelector;
