import { useEffect } from "react";
import { Route, Routes } from "react-router-dom";
import "./App.css";
import Footer from "./components/Footer";
import Home from "./components/Home";
import { Navbar } from "./components/Navbar";
import Privacy from "./components/privacy";

function App() {
  useEffect(() => {
    document.title = "GameTask";
  }, []);

  return (
    <>
      <Navbar />

      <Routes>
        <Route path="/" Component={Home} />
      </Routes>

      <Routes>
        <Route path="/privacy" Component={Privacy} />
      </Routes>

      <Footer></Footer>
    </>
  );
}

export default App;
