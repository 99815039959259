import React from "react";
import { useTranslation } from "react-i18next";

const BenefitsSection: React.FC = () => {
  const { t } = useTranslation();

  return (
    <div>
      <div className="px-12 pb-4 font-bold text-4xl pt-12">
        {t("benefits.title")}
      </div>

      <div className="grid grid-cols-1 md:grid-cols-2 my-12">
        {/* Card 1 */}
        <div className="flex flex-col items-start bg-blue-50 py-6 px-12  rounded-lg">
          <img
            src="/img/rocket.png"
            alt="Rocket Icon"
            className="w-10 h-10 mb-4"
          />
          <h3 className="text-blue-600 font-semibold text-lg">
            {t("benefits.card1.title")}
          </h3>
          <p className="text-gray-700 mt-2">
            {t("benefits.card1.description")}
          </p>
        </div>

        {/* Card 2 */}
        <div className="flex flex-col items-start bg-green-50 py-6 px-12 rounded-lg">
          <img src="/img/ear.png" alt="Ear Icon" className="w-10 h-10 mb-4" />
          <h3 className="text-green-600 font-semibold text-lg">
            {t("benefits.card2.title")}
          </h3>
          <p className="text-gray-700 mt-2">
            {t("benefits.card2.description")}
          </p>
        </div>

        {/* Card 3 */}
        <div className="flex flex-col items-start bg-pink-50 py-6 px-12 rounded-lg">
          <img
            src="/img/puzzle.png"
            alt="Puzzle Icon"
            className="w-10 h-10 mb-4"
          />
          <h3 className="text-primary font-semibold text-lg">
            {t("benefits.card3.title")}
          </h3>
          <p className="text-gray-700 mt-2">
            {t("benefits.card3.description")}
          </p>
        </div>

        {/* Card 4 */}
        <div className="flex flex-col items-start bg-yellow-50 py-6 px-12 rounded-lg">
          <img
            src="/img/clock.png"
            alt="Clock Icon"
            className="w-10 h-10 mb-4"
          />
          <h3 className="text-yellow-600 font-semibold text-lg">
            {t("benefits.card4.title")}
          </h3>
          <p className="text-gray-700 mt-2">
            {t("benefits.card4.description")}
          </p>
        </div>
      </div>
    </div>
  );
};

export default BenefitsSection;
